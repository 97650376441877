import React, { useEffect } from "react";

const BannerWidget = () => {
  useEffect(() => {
    const widgetContainer = document.getElementById("banner-widget-js");

    const widgetScript = document.createElement("script");
    widgetScript.setAttribute("type", "text/javascript");
    widgetScript.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    widgetScript.async = true;
    widgetScript.innerHTML = `
      {
        "symbols": [
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "US 100"
          },
          {
            "proName": "FX_IDC:EURUSD",
            "title": "EUR/USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          }
        ],
        "showSymbolLogo": false,
        "colorTheme": "light",
        "isTransparent": true,
        "displayMode": "regular",
        "locale": "en"
      }
    `;
    //widgetContainer.appendChild(widgetScript);
  }, []);

  return (
    <div className="banner-widget" id="banner-widget-js">
      {/* Code is appended inside here */}
    </div>
  );
};

export default BannerWidget;
